import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as usersAPI from '../../services/usersAPI';
import { TYPES } from '../../constants';

export const getUsers = createAsyncThunk(
  TYPES.USERS.GET_USERS,
  async (currentPage, { rejectWithValue }) => {
    try {
      const { data } = await usersAPI.getUsers(currentPage);
      return data;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      return rejectWithValue(error);
    }
  },
);

export const getUser = createAsyncThunk(
  TYPES.USERS.GET_USER,
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await usersAPI.getUser(userId);
      return data;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      return rejectWithValue(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  TYPES.USERS.UPDATE_USER,
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await usersAPI.updateUser(userData);
      toast.success('User Information was successfully updated.');
      return data;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      return rejectWithValue(error);
    }
  },
);
