import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserToken } from '../../redux/auth/auth-selectors';
import { getRole } from '../../redux/profile/profile-selectors';
import { ROUTES } from '../../constants';
import { useProfile } from '../../hooks/useProfile';

export const PrivateAdminRoute = ({ children, exact = false, path }) => {
  useProfile();

  const hasToken = useSelector(getUserToken);
  const role = useSelector(getRole);
  const adminRole = parseInt(role) === 1;
  const isAdmin = hasToken && adminRole;
  const isLoading = role === null;

  return (
    !isLoading && (
      <Route exact={exact} path={path}>
        {isAdmin ? children : <Redirect exact to={ROUTES.FORBIDDEN} />}
      </Route>
    )
  );
};

PrivateAdminRoute.defaultProps = {
  exact: false,
};

PrivateAdminRoute.propTypes = {
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
};
