import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import persistStore from 'redux-persist/es/persistStore';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/auth-slice';
import profileReducer from './profile/profile-slice';
import usersReducer from './users/users-slice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    profile: profileReducer,
    users: usersReducer,
  },
  middleware: [thunk, logger],
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);
