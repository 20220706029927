import axios from 'axios';
import { ENDPOINTS } from '../constants';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const getUsers = async currentPage => {
  const response = await axios.get(ENDPOINTS.USERS.ROOT, {
    params: {
      page: currentPage,
    },
  });
  return response;
};

export const getUser = async userId => {
  const response = await axios.get(`${ENDPOINTS.USERS.ROOT}/${userId}`);
  return response;
};

export const updateUser = async userData => {
  const { userId, userFormData } = userData;
  const response = await axios.put(
    `${ENDPOINTS.USERS.ROOT}/${userId}`,
    userFormData,
  );
  return response;
};
