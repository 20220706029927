import React, { lazy, Suspense, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '@fontsource/roboto';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from '@mui/material';
import './App.css';
import { Loader } from './components/Loader';
import { AppBarMui } from './components/AppBar';
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';
import { PrivateAdminRoute } from './components/PrivateAdminRoute/PrivateAdminRoute';
import { ROUTES } from './constants';
import { fetchCurrentUser } from './redux/auth/auth-operations';
import { getIsFetchingCurrentUser } from './redux/auth/auth-selectors';

const SignInView = lazy(() =>
  import(
    './views/SignInView/SignInView' /* webpackChunkName: "sign-in-view" */
  ),
);
const ProfileView = lazy(() =>
  import(
    './views/ProfileView/ProfileView' /* webpackChunkName: "profile-view" */
  ),
);
const UsersListView = lazy(() =>
  import(
    './views/UsersListView/UsersListView' /* webpackChunkName: "users-list-view" */
  ),
);
const UserView = lazy(() =>
  import('./views/UserView/UserView' /* webpackChunkName: "user-view" */),
);
const NotFoundView = lazy(() =>
  import(
    './views/NotFoundView/NotFoundView' /* webpackChunkName: "not-found-view" */
  ),
);
const ForbiddenView = lazy(() =>
  import(
    './views/ForbiddenView/ForbiddenView' /* webpackChunkName: "forbidden-view" */
  ),
);

function App() {
  const dispatch = useDispatch();
  const isFetchingCurrentUser = useSelector(getIsFetchingCurrentUser);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  return (
    !isFetchingCurrentUser && (
      <>
        <AppBarMui />
        <Container>
          <Suspense fallback={<Loader />}>
            <Switch>
              <PublicRoute exact path={ROUTES.ROOT} restricted>
                <SignInView />
              </PublicRoute>
              <PrivateRoute path={ROUTES.PROFILE}>
                <ProfileView />
              </PrivateRoute>
              <PrivateAdminRoute exact path={ROUTES.USERS}>
                <UsersListView />
              </PrivateAdminRoute>
              <PrivateAdminRoute path={ROUTES.USER}>
                <UserView />
              </PrivateAdminRoute>
              <PublicRoute exact path={ROUTES.FORBIDDEN}>
                <ForbiddenView />
              </PublicRoute>
              <PublicRoute>
                <NotFoundView />
              </PublicRoute>
            </Switch>
          </Suspense>
        </Container>
        <ToastContainer position="top-right" autoClose={3000} theme="light" />
      </>
    )
  );
}

export default App;
