import { createSelector } from '@reduxjs/toolkit';

export const getUsersItems = state => state.users.items;

export const getUserInfo = state => state.users.user;

export const getIsLoadingUser = state => state.users.isLoadingUser;

export const getSearchValue = state => state.users.searchValue;

export const getTotalPagesNumber = state => state.users.totalPagesNumber;

export const getPage = state => state.users.page;

export const getFilteredUsers = createSelector(
  [getUsersItems, getSearchValue],
  (users, searchValue) => {
    const normalizedSearchValue = searchValue.toLowerCase();

    return users.filter(user =>
      user.name.toLowerCase().includes(normalizedSearchValue),
    );
  },
);
