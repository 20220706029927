import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as profileAPI from '../../services/profileAPI';
import { TYPES, ERRORS } from '../../constants';

export const getProfile = createAsyncThunk(
  TYPES.PROFILE.GET_PROFILE,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await profileAPI.getProfile();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateProfile = createAsyncThunk(
  TYPES.PROFILE.UPDATE_PROFILE,
  async (profileData, { rejectWithValue }) => {
    try {
      const { data } = await profileAPI.updateProfile(profileData);
      toast.success('Your profile was successfully updated.');
      return data;
    } catch (error) {
      toast.error(
        'Something went wrong updating your Profile. Please try again.',
      );
      return rejectWithValue(error);
    }
  },
);

export const updateAvatar = createAsyncThunk(
  TYPES.PROFILE.UPDATE_AVATAR,
  async (avatarData, { rejectWithValue }) => {
    try {
      const result = await profileAPI.uploadAvatar(avatarData);
      const avatarId = { fileId: result.data.id };
      const { data } = await profileAPI.updateAvatar(avatarId);
      toast.success('Avatar was successfully uploaded.');
      return data;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      return rejectWithValue(error);
    }
  },
);

export const deleteAvatar = createAsyncThunk(
  TYPES.PROFILE.DELETE_AVATAR,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await profileAPI.deleteAvatar();
      toast.success('Avatar was successfully deleted.');
      return data;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      return rejectWithValue(error);
    }
  },
);

export const changePassword = createAsyncThunk(
  TYPES.PROFILE.CHANGE_PASSWORD,
  async (passwordData, { rejectWithValue }) => {
    try {
      const { data } = await profileAPI.changePassword(passwordData);
      toast.success('Your password was successfully changed.');
      return data;
    } catch (error) {
      if (error.response.data.message === ERRORS.INVALID_PASSWORD) {
        toast.error('Invalid current password. Please try again.');
      } else {
        toast.error('Something went wrong. Please try again.');
      }
      return rejectWithValue(error.message);
    }
  },
);
