import axios from 'axios';
import { ENDPOINTS } from '../constants';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const getProfile = async () => {
  const response = await axios.get(ENDPOINTS.PROFILE.ROOT);
  return response;
};

export const updateProfile = async profileData => {
  const response = await axios.put(ENDPOINTS.PROFILE.ROOT, profileData);
  return response;
};

export const uploadAvatar = async avatarFile => {
  const response = await axios.post(
    ENDPOINTS.PROFILE.UPLOAD_AVATAR,
    avatarFile,
  );
  return response;
};

export const updateAvatar = async avatarData => {
  const response = await axios.put(ENDPOINTS.PROFILE.AVATAR, avatarData);
  return response;
};

export const deleteAvatar = async () => {
  const response = await axios.delete(ENDPOINTS.PROFILE.AVATAR);
  return response;
};

export const changePassword = async passwordData => {
  const response = await axios.put(
    ENDPOINTS.PROFILE.CHANGE_PASSWORD,
    passwordData,
  );
  return response;
};
