export const ENDPOINTS = {
  AUTH: {
    LOGIN: 'auth/login',
  },

  PROFILE: {
    ROOT: 'auth/profile',
    UPLOAD_AVATAR: 'files/upload/image',
    AVATAR: 'auth/avatar',
    CHANGE_PASSWORD: 'auth/password',
  },

  USERS: {
    ROOT: 'users',
  },
};
