import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getIsSignedIn } from '../../redux/auth/auth-selectors';
import { ROUTES } from '../../constants';
import { getRole } from '../../redux/profile/profile-selectors';
import styles from './Navigation.module.scss';

export const Navigation = () => {
  const isSignedIn = useSelector(getIsSignedIn);
  const role = useSelector(getRole);
  const adminRole = parseInt(role) === 1;
  const isAdmin = isSignedIn && adminRole;

  return (
    <nav>
      {isSignedIn && (
        <NavLink
          exact
          to={ROUTES.PROFILE}
          className={styles.navLink}
          activeClassName={styles.activeNavLink}
        >
          My Profile
        </NavLink>
      )}

      {isAdmin && (
        <NavLink
          exact
          to={ROUTES.USERS}
          className={styles.navLink}
          activeClassName={styles.activeNavLink}
        >
          Users List
        </NavLink>
      )}
    </nav>
  );
};
