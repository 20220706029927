import { createTheme } from '@mui/material';

export const theme = createTheme({
  breakpoints: {
    values: {
      sm: '320px',
      md: '768px',
      lg: '1200px',
    },
  },
  palette: {
    primary: {
      main: '#607D8B',
      light: '#CFD8DC',
      contrastText: '#212121',
    },
    secondary: {
      main: '#757575',
      contrastText: '#fff',
    },
    background: {
      default: '#eeeeee',
      paper: '#fff',
    },
  },
  typography: {
    body1: {
      fontSize: 14,
    },
  },
});
