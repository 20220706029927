import Spinner from 'react-loader-spinner';
import styles from './Loader.module.scss';

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <Spinner
        type="Circles"
        color="#455a64"
        height={100}
        width={100}
        timeout={2000}
      />
    </div>
  );
};
