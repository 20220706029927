import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsSignedIn } from '../../redux/auth/auth-selectors';
import { ROUTES } from '../../constants';

export const PublicRoute = ({
  children,
  exact = false,
  path,
  restricted = false,
}) => {
  const isSignedIn = useSelector(getIsSignedIn);
  const shouldRedirect = isSignedIn && restricted;
  return (
    <Route exact={exact} path={path}>
      {shouldRedirect ? <Redirect to={ROUTES.PROFILE} /> : children}
    </Route>
  );
};

PublicRoute.defaultProps = {
  exact: false,
  restricted: false,
  path: '/',
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string,
  restricted: PropTypes.bool,
};
