import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../constants';
import styles from './AuthNav.module.scss';

export const AuthNav = () => {
  return (
    <NavLink to={ROUTES.ROOT} exact className={styles.link}>
      Sign In
    </NavLink>
  );
};
