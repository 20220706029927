import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as authAPI from '../../services/authAPI';
import { TYPES, ERRORS } from '../../constants';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const token = {
  set(bearerToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = '';
  },
};

export const signIn = createAsyncThunk(
  TYPES.AUTH.SIGNIN,
  async (credentials, { rejectWithValue }) => {
    try {
      const { data } = await authAPI.signIn(credentials);
      token.set(data.accessToken);
      toast.success('Welcome to the Test SPA!');
      return data;
    } catch (error) {
      if (error.response.data.message === ERRORS.INVALID_CREDENTIALS) {
        toast.error('Invalid credentials. Please try again.');
      } else {
        toast.error('Something went wrong. Please try again.');
      }
      return rejectWithValue(error.message);
    }
  },
);

export const signOut = createAsyncThunk(
  TYPES.AUTH.SIGNOUT,
  async (_, { rejectWithValue }) => {
    try {
      token.unset();
      return 'Sign Out was successful.';
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      return rejectWithValue(error);
    }
  },
);

export const fetchCurrentUser = createAsyncThunk(
  TYPES.AUTH.FETCH_USER,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      return thunkAPI.rejectWithValue();
    }

    token.set(persistedToken);
  },
);
