import { useSelector } from 'react-redux';
import { getIsSignedIn } from '../../redux/auth/auth-selectors';
import { AppBar, Container } from '@mui/material';
import { Navigation } from '../Navigation';
import { UserMenu } from '../UserMenu';
import { AuthNav } from '../AuthNav';
import styles from './AppBar.module.scss';

export const AppBarMui = () => {
  const isSignedIn = useSelector(getIsSignedIn);
  return (
    <AppBar>
      <Container>
        <div className={styles.appBar}>
          <Navigation />
          {isSignedIn ? <UserMenu /> : <AuthNav />}
        </div>
      </Container>
    </AppBar>
  );
};
