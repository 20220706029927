import { createSlice } from '@reduxjs/toolkit';
import { authOperations } from '../auth';

const initialState = {
  token: null,
  isSignedIn: false,
  isFetchingCurrentUser: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [authOperations.signIn.fulfilled](state, { payload }) {
      state.token = payload.accessToken;
      state.isSignedIn = true;
    },
    [authOperations.signOut.fulfilled](state) {
      state.token = null;
      state.isSignedIn = false;
    },
    [authOperations.fetchCurrentUser.pending](state) {
      state.isFetchingCurrentUser = true;
    },
    [authOperations.fetchCurrentUser.fulfilled](state) {
      state.isSignedIn = true;
      state.isFetchingCurrentUser = false;
    },
    [authOperations.fetchCurrentUser.rejected](state) {
      state.isFetchingCurrentUser = false;
    },
  },
});

const authReducer = authSlice.reducer;

export default authReducer;
