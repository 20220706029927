import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authOperations } from '../../redux/auth';
import { Button } from '@mui/material';
import styles from './UserMenu.module.scss';

export const UserMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(authOperations.signOut());
    history.push('/');
  };

  return (
    <div className={styles.container}>
      <Button
        type="button"
        onClick={handleClick}
        variant="contained"
        color="secondary"
      >
        Sign Out
      </Button>
    </div>
  );
};
