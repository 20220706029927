export const TYPES = {
  AUTH: {
    SIGNIN: 'auth/signIn',
    SIGNOUT: 'auth/signOut',
    FETCH_USER: 'auth/fetchCurrentUser',
  },
  PROFILE: {
    GET_PROFILE: 'profile/getProfile',
    UPDATE_PROFILE: 'profile/updateProfile',
    UPDATE_AVATAR: 'profile/updateAvatar',
    DELETE_AVATAR: 'profile/deleteAvatar',
    CHANGE_PASSWORD: 'profile/changePassword',
  },
  USERS: {
    GET_USERS: 'users/getUsers',
    GET_USER: 'users/getUser',
    UPDATE_USER: 'users/updateUser',
  },
};
