import { createSlice } from '@reduxjs/toolkit';
import { usersOperations } from '../users';

const initialState = {
  items: [],
  searchValue: '',
  user: null,
  isLoadingUser: false,
  totalPagesNumber: null,
  page: 1,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
  },
  extraReducers: {
    [usersOperations.getUsers.fulfilled]: (state, { payload }) => {
      state.items = payload.items;
      state.totalPagesNumber = payload.meta.totalPages;
      state.page = payload.meta.currentPage;
    },
    [usersOperations.getUser.pending]: state => {
      state.user = null;
      state.isLoadingUser = true;
    },
    [usersOperations.getUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isLoadingUser = false;
    },
    [usersOperations.getUser.rejected]: state => {
      state.isLoadingUser = false;
    },
    [usersOperations.updateUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
    },
  },
});

const usersReducer = usersSlice.reducer;
export default usersReducer;

export const { changeSearchValue } = usersSlice.actions;
