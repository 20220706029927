import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserToken } from '../../redux/auth/auth-selectors';
import { ROUTES } from '../../constants';

export const PrivateRoute = ({ children, exact = false, path }) => {
  const hasToken = useSelector(getUserToken);
  return (
    <Route exact={exact} path={path}>
      {hasToken ? children : <Redirect exact to={ROUTES.ROOT} />}
    </Route>
  );
};

PrivateRoute.defaultProps = {
  exact: false,
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
};
