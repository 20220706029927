import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../redux/profile/profile-operations';
import { getProfileId } from '../redux/profile/profile-selectors';

export const useProfile = () => {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  useEffect(() => {
    if (!profileId) {
      dispatch(getProfile());
    }
  }, [profileId]);
};
