import { createSlice } from '@reduxjs/toolkit';
import { profileOperations } from '../profile';

const initialState = {
  profileInfo: null,
  profileId: null,
  avatar: '',
  role: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: {
    [profileOperations.getProfile.pending](state) {
      state.profileInfo = null;
      state.profileId = null;
      state.avatar = null;
      state.role = null;
    },
    [profileOperations.getProfile.fulfilled](state, { payload }) {
      state.profileInfo = payload;
      state.profileId = payload.id;
      state.avatar = payload.avatar;
      state.role = payload.role;
    },
    [profileOperations.updateProfile.fulfilled](state, { payload }) {
      state.profileInfo = payload;
    },
    [profileOperations.updateAvatar.fulfilled](state, { payload }) {
      state.avatar = payload;
    },
    [profileOperations.deleteAvatar.fulfilled](state) {
      state.avatar = null;
    },
  },
});

const profileReducer = profileSlice.reducer;

export default profileReducer;
